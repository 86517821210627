<template>
    <div class="category-list-page">
        <GeekQooSearch></GeekQooSearch>
        <div class="list-container">
            <div class="list-left">
                <a v-for="(item,index) in categoryList" :key="index" :class="categoryActive==index?'active':''"
                   @click="changeCategory(index)">
                    <img :src="item.icon?item.icon:app.emptyImg" draggable="false">
                    <span>{{ item.title }}</span>
                </a>
            </div>
            <div class="list-right">
                <div v-if="categoryList[categoryActive]" class="list-wrapper">
                    <a v-for="(item,index) in categoryList[categoryActive].list" :key="index" class="list-item"
                       @click="toGoodsList(item)">
                        <img :src="item.icon?item.icon:app.emptyImg" draggable="false">
                        <span>{{ item.title }}</span>
                    </a>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
// 引入mixins
import mixins_config from "@/mixins/config.js";
// 引入组件
import GeekQooSearch from "@/components/GeekQooSearch"
// 引入接口
import {GOODS_GROUP} from "@/api/dataProvider";

export default {
    name: "CategoryList",
    mixins: [mixins_config],
    components: {
        GeekQooSearch
    },
    data() {
        return {
            categoryList: [],
            categoryActive: 0,
        };
    },
    created() {
        this.app.tabActive = 1
    },
    mounted() {
        this.getGoodsGroup()
        if (this.$route.query.active) {
            this.categoryActive = this.$route.query.active
        }
    },
    methods: {
        // 获取商品分类
        getGoodsGroup() {
            let that = this;
            GOODS_GROUP({}, function (res) {
                if (res.data.code === 0) {
                    console.log(res.data.data)
                    that.categoryList = res.data.data.group
                }
            })
        },
        // 切换分类
        changeCategory(index) {
            this.categoryActive = index
            if (this.$route.query.active) {
                this.$router.replace({name: this.$route.name});
            }
        },
        // 跳转商品列表
        toGoodsList(item) {
            console.log(item)
            this.$router.push(
                {
                    name: "GoodsList",
                    query: {
                        id: `${this.categoryList[this.categoryActive].id}-${item.id}`
                    }
                }
            )
        }
    }
}
</script>

<style lang="scss">
.category-list-page {
    height: 100%;

    .list-container {
        display: flex;
        width: 100%;
        height: calc(100vh - 95px);

        .list-left {
            width: 104px;
            height: 100%;
            background: #EEEEEE;

            a {
                display: block;
                padding: 14px 0 14px 14px;
                border-left: 3px solid transparent;

                img {
                    vertical-align: middle;
                    width: 21px;
                    height: 21px;
                    border-radius: 50%;
                }

                span {
                    vertical-align: middle;
                    margin-left: 7px;
                    font-size: 10px;
                    color: #2D2D2D;
                }
            }

            .active {
                background: #fff;
                color: #F96719;
                border-left: 3px solid #F96719;
            }
        }

        .list-right {
            width: calc(100vw - 104px);
            overflow-y: scroll;

            .list-wrapper {
                padding: 8px;

                .list-item {
                    display: inline-block;
                    width: 69px;
                    margin: 6px;

                    img {
                        display: block;
                        width: 69px;
                        height: 69px;
                        object-fit: cover;
                    }

                    span {
                        display: block;
                        width: 100%;
                        margin-top: 14px;
                        text-align: center;
                        font-size: 10px;
                        color: #2D2D2D;
                    }
                }
            }
        }
    }
}
</style>